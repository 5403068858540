import {
  Server
} from "./chunk.WLUKVG5Y.js";

// universal_no-prefetch.tsx?intercept
import React2 from "react";

// src/pages/no-prefetch/index.tsx
import React, { useEffect, useState } from "react";
var App = () => {
  const [repos, setRepos] = useState([]);
  useEffect(() => {
    fetch("https://vercel-api-wheat.vercel.app/repos.json").then((data) => data.json()).then(setRepos);
  }, []);
  if (typeof window === "undefined") {
    console.log("page detail, num of repos", repos.length);
  } else {
    console.log("page detail, repos", repos);
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    onClick: () => {
      console.log(1);
    }
  }, "detail (example of getServerSideProps)"), repos.map((repo) => /* @__PURE__ */ React.createElement("div", {
    className: "block",
    key: repo.full_name
  }, /* @__PURE__ */ React.createElement("span", null, "html_url:"), /* @__PURE__ */ React.createElement("span", null, repo.html_url), /* @__PURE__ */ React.createElement("span", null, "description:"), /* @__PURE__ */ React.createElement("span", null, repo.description), /* @__PURE__ */ React.createElement("hr", null))));
};
var no_prefetch_default = App;

// universal_no-prefetch.tsx?intercept
var Component = (props) => {
  return /* @__PURE__ */ React2.createElement(Server, {
    ...props
  }, /* @__PURE__ */ React2.createElement(no_prefetch_default, null));
};
export {
  Component
};
